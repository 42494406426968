import * as React from "react";
import styled from "styled-components";
import { theme } from "./colors";

const SectionArea= styled.div`
	width: 100%;
	padding: 3.2rem 0;
	background-color: ${p => p.bgColor ? p.bgColor : theme.text};
	color: ${p => p.textColor ? p.textColor : '#000000'};
	font-weight: 300;
	display: flex;
	justify-content:center;
`;

const SectionContent = styled.div`
	display: flex;
	max-width: 1240px;
	width:100%;
	padding:0 2rem;
	flex-direction: column;
	/* font-size: 1.4rem; */
`
export const SectionTitle = styled.div`
	font-size:2.4rem;
	font-weight: 300;
	padding-bottom: ${p => p.noMargin ? '0' : '1.2rem'};
	color: ${theme.action};
	;
`

const Section = (props) => {
	return (
		<SectionArea bgColor={props.bgColor} textColor={props.textColor}>
			<SectionContent>
				{props.title && <SectionTitle>{props.title}</SectionTitle> }
				{props.children}
			</SectionContent>
		</SectionArea>
	)
}

export default Section