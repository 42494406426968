import React from "react"
import {theme} from '../utilis/colors'
import { createGlobalStyle } from "styled-components";
import "@fontsource/lato";
import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";


const GlobalStyle = createGlobalStyle`
  body {
    padding:0;
    margin:0;
		font-size: 62.5%;
		box-sizing: border-box;
		font-family: "Lato";
    background-color: ${theme.mainBackground}
  }
	*{
		box-sizing: border-box;
	}
`
export default function Layout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  )
}